import axios from 'axios';
import https from 'https';
import { saveToken, loadToken } from '../utils/sessionStorage';
import { GRANT_TYPE } from '../utils/tokenConfig';
import qs from 'querystring'

export const clientOAuth = () => {
    
    const httpsAgent = new https.Agent({
        rejectUnauthorized: false,
    })
    axios.defaults.options = httpsAgent
    const params = {
        grant_type: GRANT_TYPE,
    };
    const oauth2 = (url, refresh = false) => {
        if (!url.startsWith('/tenant')) {
            return new Promise(function (resolve) {
                resolve('');
            });
        }

        if (!refresh) {
            const saved_token = loadToken().access_token;
            if (saved_token !== 'undefined' && saved_token !== undefined) {
                return new Promise(function (resolve) {
                    resolve(saved_token);
                });
            }
        }

        return new Promise(function (resolve, reject) {
            axios.post('/access_token', qs.stringify(params), {
            }).then(response => {
                saveToken(response.data);
                resolve(response.data.access_token);
            }).catch(function (error) {
                reject(error);
            });
        });
    };

    return {
        get: (url, options = {}, attempt = 0) => {
            return oauth2(url).then(function (token) {
                const headers = {
                    Authorization: token !== '' ? `Bearer ${token}` : ''
                }
                if (attempt < 2) {
                    const interceptor = axios.interceptors.response.use(
                        response => {
                            axios.interceptors.response.eject(interceptor);
                            return response;
                        }, error => {
                            axios.interceptors.response.eject(interceptor);
                            if (error.response.status !== 401) {
                                Promise.reject(error);
                                throw error;
                            }

                            return clientOAuth().get(url, options, attempt + 1);
                        });
                }
                return axios.get(url, { headers: headers, ...options });
            });
        },
        post: (url, data, options = {}, attempt = 0) => {
            return oauth2(url, attempt > 0).then(function (token) {
                const headers = {
                    Authorization: token !== '' ? `Bearer ${token}` : ''
                }
                if (attempt < 2) {
                    const interceptor = axios.interceptors.response.use(
                        response => {
                            axios.interceptors.response.eject(interceptor);
                            return response;
                        }, error => {
                            axios.interceptors.response.eject(interceptor);
                            console.log(error.response)
                            if (error.response.status !== 401) {
                                Promise.reject(error.response);
                                throw error.response;
                            }

                            return clientOAuth().post(url, data, options, attempt + 1);
                        });
                }
                return axios.post(url, data, { headers: headers, ...options });
            });
        },
        put: (url, data, options = {}, attempt = 0) => {
            return oauth2(url).then(function (token) {
                const headers = {
                    Authorization: token !== '' ? `Bearer ${token}` : ''
                }
                if (attempt < 2) {
                    const interceptor = axios.interceptors.response.use(
                        response => {
                            axios.interceptors.response.eject(interceptor);
                            return response;
                        }, error => {
                            axios.interceptors.response.eject(interceptor);
                            if (error.response.status !== 401) {
                                Promise.reject(error);
                                throw error;
                            }

                            return clientOAuth().put(url, data, options, attempt + 1);
                        });
                }

                return axios.put(url, data, { headers: headers, ...options });
            });
        },
        delete: (url, options = {}, attempt = 0) => {
            return oauth2(url).then(function (token) {
                const headers = {
                    Authorization: token !== '' ? `Bearer ${token}` : ''
                }
                if (attempt < 2) {
                    const interceptor = axios.interceptors.response.use(
                        response => {
                            axios.interceptors.response.eject(interceptor);
                            return response;
                        }, error => {
                            axios.interceptors.response.eject(interceptor);
                            if (error.response.status !== 401) {
                                Promise.reject(error);
                                throw error;
                            }

                            return clientOAuth().delete(url, options, attempt + 1);
                        });
                }
                return axios.delete(url, { headers: headers, ...options });
            });
        },
    };
};