import { clientOAuth } from '../utils/clientOAuth'
import { saveToken, loadToken } from '../utils/sessionStorage';

export const SET_LOGIN_INFORMATION = 'SET_LOGIN_INFORMATION';
export const SET_CHALLENGE_INFORMATION = 'SET_CHALLENGE_INFORMATION';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_RESPONSE_ERROR = 'SET_RESPONSE_ERROR';
export const SET_VALIDATE_LOGIN_ERROR = 'SET_VALIDATE_LOGIN_ERROR'
export const SET_LOGIN = 'SET_LOGIN';

export const setLoginInformation = (data) => ({ type: SET_LOGIN_INFORMATION, data });
export const setChallengeInformation = (data) => ({ type: SET_CHALLENGE_INFORMATION, data });
export const setLoginError = (data) => ({ type: SET_LOGIN_ERROR, data });
export const setLoggedIn = () => ({ type: SET_LOGGED_IN })
export const setResponseError = (data) => ({ type: SET_RESPONSE_ERROR, data });
export const setValidateLoginError = (data) => ({ type: SET_VALIDATE_LOGIN_ERROR, data })
export const setLogin = (data) => ({ type: SET_LOGIN, data })


export const validateLogin = (login, password) => (dispatch) => {
    const request = clientOAuth();
    sessionStorage.setItem('loggedIn', false);
    request.post("/access_token", 
    {
        login: login,
        password: password
    }, {
    }).then((response) => {
        if (response.status === 200) {
            if(response.data.authentificationLdap === true){
                if(response.data.challengeDetails !== null && response.data.authentificationMFA === true){
                    let challengeInfo = {...response.data.challengeDetails, ...{challengeReceptionMode : response.data.challengeReceptionMode}};
                    dispatch(setLoginInformation(challengeInfo));
                    dispatch(setChallengeInformation(response.data.challengeDetails));
                    dispatch(setLoginError());    
                }else{
                    if(response.data.authentificationMFA === false){                        
                        dispatch(setLoggedIn());
                        sessionStorage.setItem('loggedIn', true);
                    }else{
                        dispatch(setLoginInformation());        
                        dispatch(setResponseError(response.data.message));
                        // dispatch(setLoginError("hia.convention.pending"));   
                    } 
                }    
            }else{     
                dispatch(setResponseError(response.data.message));
                dispatch(setLoginError(response.data.message));   
            }
        }else if(response.status === 404){
            dispatch(setLoginInformation());
            dispatch(setResponseError(response.data.message));
            dispatch(setLoginError("hia.convention.pending"));   
        }else{
            dispatch(setLoginInformation());
            dispatch(setLoginError(response.data.message));   
            dispatch(setResponseError(response.data.message));
        }
    }).catch(function (error) {
        if (error.status) {
            dispatch(setLoginError(error.data.message));  
            dispatch(setResponseError(error.data.message)); 
        }else{  
            dispatch(setLoginError(error.response.data.message));  
            dispatch(setResponseError(error.response.data.message)); 
        }        
    })
}


export const getChallenge = (challengeId, userId) => (dispatch) => {
    // var encodedUserId = userId = btoa(userId);//Base64
    // encodedUserId = encodedUserId.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');//Base64URL

    // const request = clientOAuth();
    // request.get(`/tenant/api/v1/client-users/challenges/${challengeId}?userId=${encodedUserId}`)
    // .then((response) => {
    //     if (response.status === 200) {
    //         dispatch(setChallengeInformation(response.data))
    //     }
    // }).catch(function (error) {
    //     dispatch(setResponseError(error.response.data.errors[0].key));
    // })
}

export const checkChallenge = (challengeId, userId, challengeResponse) => (dispatch) => {
    const request = clientOAuth();
    request.post("/check-challenge", {
        "challengeId": challengeId,
        "challengeResponse": challengeResponse,
        "userId": userId
      }, {
    })
    .then((response) => {
        if (response.status === 200) {
            dispatch(setLoggedIn());
            sessionStorage.setItem('loggedIn', true);
        }else{
            dispatch(setLoginInformation(response.data.context));
            dispatch(setChallengeInformation(response.data));
            dispatch(setResponseError(response.data.authentificationLdap));
        }
    }).catch(function (error) {
        dispatch(setResponseError(true));        
    })
}

export const answerChallenge = (challengeId, userId, response) => (dispatch) => {

    const request = clientOAuth();
    request.put(`/tenant/api/v1/client-users/challenges?challengeId=${challengeId}&response=${response}&userId=${userId}`, {
    }).then((response) => {
        if (response.status === 200) {
            dispatch(setLoggedIn())
        }
    }).catch(function (error) {
        if (error.response.data.result) {
            dispatch(setResponseError(error.response.data.result));
            dispatch(setChallengeInformation(error.response.data))
        }
        else {
            dispatch(setResponseError(error.response.data.errors[0].key));
        }
    })
}

export const createNewConvention = (login) => (dispatch) => {
    const request = clientOAuth();
    request.post(`/tenant/api/v1/accounts/new-convention`, { login: login }, {
    }).then((response) => {
        if (response.status === 200) {
            dispatch(setValidateLoginError(true));
        }
    }).catch(function (error) {
        if (error.response.data.errors) { dispatch(setValidateLoginError(error.response.data.errors[0].key)); }
    })
}

