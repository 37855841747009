import React from 'react';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import '../../../utils/styles/Layout.css';

const Component = ({ isLoggedIn }) => {
    return (
        <Grid container>     
            <div onClick={() => { window.location.href = 'https://hiasecure.com/'; }} id="hia_back_btn">
                <img src="back-arrow.png" alt="Icon retour"/>
            </div> 
            {!(isLoggedIn && (sessionStorage.getItem("loggedIn") && sessionStorage.getItem("loggedIn") === false) ) &&
                <div id="hia_footer_right">If you want to open a test account, please contact HIAsecure on the <a id="hia_link_contact_page" target="_blank" href='https://hiasecure.com/contact/'>contact page</a>.</div>
            }
            <Grid item sm={12}>
            {(!isLoggedIn && (sessionStorage.getItem("loggedIn") && sessionStorage.getItem("loggedIn") === false) ) ?
                <div id='hia_btn'>
                    <div id="hia_logo">
                        <img src="showroomSATOL.png" alt="Logo Showroom"/>
                    </div>
                    <div className="text head">Access a library of reports, white papers and regulatory texts<br/> selected by our cybersecurity experts</div>
                    <div className="btn" onClick={() => { window.location.href = '/connect?redirectTo=/admin'; }}>Sign in</div>
                    <div className="text bottom">Protected by <img src="logo-HIA-secure-sans-secure-white.png" alt="Logo HIASecure white"/></div>
                </div> : null
            }
            </Grid>
        </Grid>
    )
}

export const enhance = compose();
export default enhance(Component)